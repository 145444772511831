@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import url('https://fonts.googleapis.com/css?family=Titillium+Web|Turret+Road&display=swap');


// font-family: 'Turret Road', cursive;

// font-family: 'Titillium Web', sans-serif;


// colors
$black: #050411;
$deepgrey: #231957;
$medgrey: #4f5065;
$brightpurple: #281DA3;
$brightblue: #035Be8;
$darkmagenta: #3D2270;
$dimpurple: #3E379D;
$lightgrey: #93AEC7;



body {
  background-color: $black;
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  color: $lightgrey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & .bp3-running-text h1,
  .bp3-running-text h2,
  .bp3-running-text h3,
  .bp3-running-text h4,
  .bp3-running-text h5,
  .bp3-running-text h6 {
    color: $dimpurple;
    font-family: 'Turret Road', cursive;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.article-wrapper {
  width: 100%;
  max-width: 800px;
  padding: 16px;
}

.nav {


  &-wrapper {
    height: 50px;
  }

}

.home {
  &-banner {
    height: 550px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("./bg.jpg");
    background-position: center center;
    background-size: cover;
    justify-content: center;
    background-position-y: 60%;

    .home-title {
      color: $lightgrey;
      font-size: 3.5em
    }
  }

}

.glossary {
  .bp3-input {
    border: 1px solid $dimpurple;
  }

  .word {
    &-link {
      opacity: 0;
      transition: 0.3s;
      cursor: pointer;
    }

    &-card:hover .word-link {
      opacity: 1;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
}